import React, { useEffect } from "react"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import { graphql } from "gatsby"
import shortid from "shortid"
import IconButton from "@material-ui/core/IconButton"

import SidebarMenu from "../components/SidebarMenu"
import SEO from "../components/SEO.js"

import "slick-carousel/slick/slick.css"

const PortfolioCategory = ({
  data: { portfolioCategory, portfolioCategories },
  pageContext: { locale, breadcrumbs, slug },
  location,
}) => {
  const meta = {
    title: portfolioCategory.data.meta_title,
    description: portfolioCategory.data.meta_description,
    keywords: portfolioCategory.data.meta_keywords,
    author: "Emil Mureșan",
  }

  const schema = {
    "@graph": [],
  }
  const ogp = []

  ogp.push({
    property: "og:image",
    content: portfolioCategory.data.gallery[0].image.fluid.src,
  })
  ogp.push({
    property: "og:image:alt",
    content: portfolioCategory.data.gallery[0].image.alt,
  })
  ogp.push({
    property: "og:image:width",
    content: portfolioCategory.data.gallery[0].image.dimensions.width,
  })
  ogp.push({
    property: "og:image:height",
    content: portfolioCategory.data.gallery[0].image.dimensions.height,
  })
  ogp.push({
    property: "og:image:type",
    content: "image/jpeg",
  })

  const categories = portfolioCategories.edges.map(r => r.node)

  const [zoom, setZoom] = React.useState(() => {
    const zoomStorage =
      typeof window !== "undefined" && window.localStorage.getItem("zoom")
    return zoomStorage === "true" || false
  })

  const handleZoom = () => {
    setZoom(!zoom)
  }

  useEffect(() => {
    localStorage.setItem("zoom", zoom)
  }, [zoom])

  return (
    <>
      <SEO
        schema={JSON.stringify(schema)}
        ogp={ogp}
        meta={meta}
        locale={locale}
        breadcrumbs={breadcrumbs}
        location={location}
      />
      <div id="portfolio" className={`category ${zoom ? "zoom-in" : ""}`}>
        {portfolioCategory.data.gallery.map(({ image }) => (
          <div key={shortid.generate()} className="full-picture">
            <Img
              alt={image.alt}
              imgStyle={{
                objectFit: zoom ? "cover" : "contain",
                objectPosition: "top center",
              }}
              fluid={image.fluid}
              className="full-image no-border"
            />
          </div>
        ))}
        <div className="zoom-btn-wrapper">
          <IconButton className="zoom-btn" onClick={handleZoom}>
            <>
              <span className="icon">{zoom ? "-" : "+"}</span>zoom
            </>
          </IconButton>
        </div>
      </div>

      <SidebarMenu
        items={categories}
        current={slug}
        breadcrumbs={breadcrumbs}
      />
    </>
  )
}

export default PortfolioCategory

export const query = graphql`
  query PortfolioCategoryQuery($slug: String!, $locale: String!) {
    portfolioCategory: prismicPortfolioCategory(
      uid: { eq: $slug }
      lang: { eq: $locale }
    ) {
      data {
        meta_title
        meta_description
        meta_keywords
        title {
          text
        }
        gallery {
          image {
            alt
            dimensions {
              height
              width
            }
            fluid {
              ...GatsbyPrismicImageFluid
            }
          }
        }
      }
    }

    portfolioCategories: allPrismicPortfolioCategory(
      filter: { lang: { eq: "ro" } }
    ) {
      edges {
        node {
          data {
            title {
              text
            }
            parent_page {
              document {
                ... on PrismicPortfolioPage {
                  uid
                  data {
                    title {
                      text
                    }
                  }
                }
              }
            }
          }
          uid
          lang
        }
      }
    }
  }
`

PortfolioCategory.propTypes = {
  data: PropTypes.shape({
    portfolioCategory: PropTypes.object.isRequired,
    portfolioCategories: PropTypes.object.isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    breadcrumbs: PropTypes.array.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
